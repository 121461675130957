.flip {
  transform: scaleX(-1);
}

.wrap {
  width: 100%;
  height: 100vh;
}
.yearWrap {
  width: 100%;
  height: 170vh;
}

.imagesWrap {
  width: 100%;
  height: 200vh;
  background-color: white;
}

.sect {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 16px;
}

.ifTextColor {
  color: #2f4858;
}
.safron {
  color: #f15b25;
}
.green {
  color: #046a38;
}
.divider {
  background-color: #282828;
  width: 100%;
  height: 1px;
}
.bgOverlay {
  background-color: #120603;
}
.secondaryPink {
  color: #e23f63;
}
.cherry {
  color: #970000;
}
.Pastel-Sand-accent {
  color: #ede9d0;
}

.circular-border-text {
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 1px solid #3b5045;
  color: #000000;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-family: "overpass";
}

.circular-border-text-selected {
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-family: "overpass";
}

.circular-border-text::after,
.circular-border-text-selected::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 25px;
  height: 0;
  background-color: #046a38;
  z-index: 0;
  transition: height 0.5s ease, color 0.5s ease;
}

.circular-border-text:hover::after {
  height: 100%;
}

.circular-border-text:hover {
  color: #fff;
}

.section {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 300%;
  height: 100vh;
  display: flex;
}

.sticky-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.sticky-year {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1000;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* Track color (transparent here for demo) */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #f15b25, white, #046a38);
  border-radius: 6px; /* Round corners */
  border: 3px solid transparent; /* Transparent border to fix issues on hover */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker handle color on hover */
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typewriter {
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Adjust grid columns as needed */
  gap: 10px; /* Adjust gap between images */
}

.grid-item {
  width: 100%; /* Ensure images take full width of their grid cell */
  height: auto; /* Maintain aspect ratio */
}

#showText {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Optional: Add background for visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other content */
  visibility: hidden; /* Initially hidden */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.custom-cursor {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  background: url("./assets/images/Ashok-chakra.png") no-repeat center center;
  background-size: contain;
  animation: rotate 2s linear infinite;
  transform: translate(-50%, -50%);
}

body.custom-cursor-active {
  cursor: none;
}
body.custom-cursor-active,
body.custom-cursor-active button,
body.custom-cursor-active a,
body.custom-cursor-active input,
body.custom-cursor-active textarea,
body.custom-cursor-active select,
body.custom-cursor-active [role="button"],
body.custom-cursor-active [onClick] {
  cursor: none !important; /* Hide the default cursor */
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.yearDivider {
  height: 2px;
  background-color: black;
  margin: 0 10px;
  transition-duration: 0.5s;
}

.descriptionBg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.text-container {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 5%;
  top: 5%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
