body {
  margin: 0;
  text-align: center;
  font-family: "kalnia", "cormorant", "overpass";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('./assets/images/Ashok-chakra.png'), auto;
}
@font-face {
  font-family: "kalnia";
  src: url("./assets/fonts/Kalnia-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cormorant";
  src: url("./assets/fonts/Cormorant-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "overpass";
  src: url("./assets/fonts/Overpass-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kaushnaScript";
  src: url("./assets/fonts/KaushanScript-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cormorant {
  font-family: "cormorant";
}

.kalnia {
  font-family: "kalnia";
}

.overpass {
  font-family: "overpass";
}
.kaushnaScript {
  font-family: "kaushnaScript";
}




@tailwind base;
@tailwind components;
@tailwind utilities;

body.hide {
  overflow: hidden;
}
